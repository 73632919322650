'use client';
import React, { useEffect } from 'react';

import { css, cx } from '@pt-group-fe/styled-system/css';
import { borderBox } from '@pt-group-fe/styled-system/recipes';
import { cmsContentPricing } from '@pt-group-fe/styled-system/recipes';
import { useMeasure } from 'react-use';

import { useIsMounted } from '@lib/hooks';
import type { Cms_ContentPricingItem } from '@lib/services/cms';
import { useTranslation } from '@lib/services/i18n/client';
import { Button } from '@lib/ui/button';
import { CmsEditorText } from '@lib/ui/cmsEditorText';
import { CmsIconClient } from '@lib/ui/cmsIcon';
import { CmsLink } from '@lib/ui/cmsLink';
import { Img } from '@lib/ui/img';
import { Text } from '@lib/ui/text';
import { getTitleTag } from '@lib/utils';

import type { PriceItemHeight, SetPriceItemPropsHandler } from './PricingList';

interface Props {
  data: Cms_ContentPricingItem;
  index: number;
  heights: PriceItemHeight;
  isIgnoreVisible?: boolean;
  setItemProps: SetPriceItemPropsHandler;
}

const PriceItem: React.FC<Props> = ({ data, index, heights, isIgnoreVisible, setItemProps }) => {
  const { item, itemTitle, services, priceLabel, price, service, cmsIcon, text, imageWrap, img } =
    cmsContentPricing({ color: data.color });
  const { t } = useTranslation();
  const [titleRef, titleMeasures] = useMeasure<HTMLDivElement>();
  const [servicesRef, servicesMeasures] = useMeasure<HTMLDivElement>();
  const links = data.links?.filter(l => l._isValid) || [];
  const isMounted = useIsMounted();

  useEffect(() => {
    if (titleMeasures.height !== heights.titleHeight) {
      setItemProps(index, 'title', titleMeasures.height);
    }
  }, [index, heights.titleHeight, setItemProps, titleMeasures.height]);

  useEffect(() => {
    if (servicesMeasures.height !== heights.servicesHeight) {
      setItemProps(index, 'services', servicesMeasures.height);
    }
  }, [index, setItemProps, servicesMeasures.height, heights.servicesHeight]);

  const priceItemContent = (
    <div className={cx('group', borderBox(), css({ overflow: 'hidden' }))}>
      <div
        className={item}
        style={{
          visibility:
            heights.titleMinHeight === 0 && heights.servicesMinHeight === 0 && !isIgnoreVisible
              ? 'hidden'
              : 'visible'
        }}
      >
        {data.image?.data && (
          <div className={cmsContentPricing({ pos: 'image' }).itemBox}>
            <div className={imageWrap}>
              <Img fill data={data.image} alt={data.title} className={img} />
            </div>
          </div>
        )}
        <div className={cmsContentPricing({ pos: 'title' }).itemBox}>
          <div className={itemTitle}>
            <Text variant="h4" as={getTitleTag(data.titleHtmlTag) || 'h4'}>
              {data.title}
            </Text>
          </div>
          {data.text && (
            <div ref={titleRef} style={{ minHeight: heights.titleMinHeight }}>
              <CmsEditorText
                isNestedLinks={data.link?._isValid}
                data={data.text}
                variant="blockSm"
              />
            </div>
          )}
        </div>
        {data.services && data.services.length > 0 && (
          <div className={cmsContentPricing({ pos: 'services' }).itemBox}>
            <div
              className={services}
              ref={servicesRef}
              style={{ minHeight: heights.servicesMinHeight }}
            >
              {data.services?.map(s => (
                <div className={service} key={s.id}>
                  <CmsIconClient i={s.icon} className={cmsIcon} />
                  <Text variant="block-sm" className={text}>
                    {s.title}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={cmsContentPricing({ pos: 'price' }).itemBox}>
          <div className={priceLabel}>
            {data.priceLabel || t('uiWeb.cmsContents.pricing.startingAt')}
          </div>
          <div className={price}>
            {data.customPrice ? (
              data.customPrice
            ) : (
              <>
                {data.priceCurrency || 'CHF'} {data.price}
                {t('uiWeb.cmsContents.pricing.perMonth')}
              </>
            )}
          </div>
        </div>
        {data.link && links.length === 0 && (
          <div className={cmsContentPricing({ pos: 'button' }).itemBox}>
            {data.link && data.link._isValid && (
              <CmsLink link={data.link}>
                <Button icon="arrow-right">
                  {data.link.title || t('uiWeb.cmsContents.pricing.startNow')}
                </Button>
              </CmsLink>
            )}
          </div>
        )}

        {links.length > 0 && (
          <div
            className={cx(
              cmsContentPricing({ pos: 'button' }).itemBox,
              css({ flexWrap: 'wrap', gap: 2 })
            )}
          >
            {links.map((link, i) => (
              <CmsLink link={link} key={i}>
                <Button
                  className={css({
                    // _groupHover: { color: 'white', bg: 'primary.dark' },
                    minW: { base: '100%', md: 'auto' }
                  })}
                  icon="arrow-right"
                >
                  {link.title || t('uiWeb.cmsContents.pricing.startNow')}
                </Button>
              </CmsLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  if (!isMounted) {
    return null;
  }

  return priceItemContent;
};

export default PriceItem;
