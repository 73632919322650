'use client';

import React from 'react';

import { css, cva } from '@pt-group-fe/styled-system/css';
import { Grid } from '@pt-group-fe/styled-system/jsx';

import type { Cms_ContentEcosystemLayer } from '@lib/services/cms';
import { CmsEditorText } from '@lib/ui/cmsEditorText';
import { Text } from '@lib/ui/text';
import { getTitleTag } from '@lib/utils';

import { LinkBox } from '../linkBox';

interface Props {
  data: Cms_ContentEcosystemLayer;
  isVisible?: boolean;
}

const box = cva({
  base: {
    opacity: 0,
    overflow: 'hidden',
    h: 0,
    zIndex: 0
  },
  variants: {
    isVisible: {
      true: {
        opacity: 1,
        zIndex: 1,
        h: 'auto',
        animation: 'fadeIn 0.3s ease-in-out forwards'
      }
    }
  }
});

const title = css({
  mb: '27px'
});

const EcosystemLayerInfo: React.FC<Props> = ({ data, isVisible }) => {
  return (
    <div className={box({ isVisible })}>
      <Text
        className={title}
        leadingNormal
        variant="h3"
        as={getTitleTag(data.titleHtmlTag) || 'h2'}
      >
        {data.title}
      </Text>
      <CmsEditorText variant="blockSm" data={data.text} />
      <Grid columns={{ base: 1, xl: 2 }} gap={4} mt="27px" className={box({ isVisible })}>
        {data.links
          ?.filter(l => l._url)
          ?.map((link, i) => (
            <LinkBox key={i} href={link._url as string} icon={link.icon}>
              {link.title}
            </LinkBox>
          ))}
      </Grid>
    </div>
  );
};

export default EcosystemLayerInfo;
