import React from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { icon } from '@pt-group-fe/styled-system/recipes';

import { Cms_Icon } from '@lib/services/cms';
import { cms } from '@lib/services/cms';

import type { CmsIconProps } from './CmsIcon.types';
import CmsIconSvgNode from './CmsIconSvgNode';

export const CmsIcon: React.FC<CmsIconProps> = async props => {
  const { i, className, size = 'default' } = props;

  if (!i) return null;

  const iconRes = (await cms.icons.getIconByName(i)) as Cms_Icon;

  if (!iconRes?.svgJson) return null;

  return (
    <CmsIconSvgNode
      className={cx('cms-icon', `cms-icon-${i}`, icon({ size }), className)}
      data={iconRes.svgJson}
      data-icon={i}
    />
  );
};
