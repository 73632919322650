'use client';

import React from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { borderBox } from '@pt-group-fe/styled-system/recipes';
import { cmsContentDownloads } from '@pt-group-fe/styled-system/recipes';
import { saveAs } from 'file-saver';
import prettyBytes from 'pretty-bytes';

import type { Cms_SingleMedia } from '@lib/services/cms';
import { Icon } from '@lib/ui/icon';

interface Props {
  file?: Cms_SingleMedia;
  title: string;
}

// const btn = borderBox({
//   display: 'flex',
//   flexDir: { base: 'column', sm: 'row' },
//   alignItems: { sm: 'center' },
//   justifyContent: 'space-between',
//   p: 4,
//   mb: { base: 4, sm: 0 },
//   cursor: 'pointer'
// });

// const titleText = css({
//   textStyle: 'block-sm',
//   fontWeight: '400',
//   lineHeight: 'normal',
//   pt: 0.5,
//   w: { base: 'full', sm: 'auto' },
//   maxW: { base: '100%', sm: '60%', lg: '70%' },
//   textOverflow: 'ellipsis',
//   overflow: { sm: 'hidden' },
//   textAlign: 'start',
//   whiteSpace: { sm: 'nowrap' }
// });

// const info = css({
//   display: 'flex',
//   gap: 6,
//   pt: 0.5,
//   fontSize: 'blockXxs',
//   lineHeight: 'blockXxs',
//   alignItems: { base: 'baseline', sm: 'center' },
//   justifyContent: { base: 'space-between', sm: 'start' },
//   w: { base: 'full', sm: 'auto' }
// });

// const infoContent = css({
//   display: 'flex',
//   alignItems: 'center'
// });

// const infoExt = css({
//   textStyle: 'block-xxs',
//   mr: 2,
//   pt: 0.5
// });

const DownloadBtn: React.FC<Props> = ({ file, title }) => {
  const { btn, titleText, info, infoContent, infoExt, icon } = cmsContentDownloads();
  const handleDonwload = () => {
    if (!file?.data) return;

    saveAs(file.data.url, file.data.name);
  };

  if (!file?.data) return null;

  return (
    <button className={cx('group', borderBox(), btn)} type="button" onClick={handleDonwload}>
      <div className={titleText}>{title}</div>
      <div className={info}>
        <div className={infoContent}>
          <div className={infoExt}>
            {file.data?.ext && file.data.ext.split('.')[1]?.toUpperCase()}
          </div>
          {prettyBytes(file.data?.size * 1000)}
        </div>
        <Icon i="download" className={icon} />
      </div>
    </button>
  );
};

export default DownloadBtn;
