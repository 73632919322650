import {
  Cms_EditorData,
  Cms_GridVariant,
  Cms_Link,
  Cms_SingleMedia,
  Cms_TitleTag
} from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export type Cms_ContentPricingItemColor = 'white' | 'primary_light';

export interface Cms_ContentPricingItemService {
  id: number;
  title: string;
  icon: string;
}

export interface Cms_ContentPricingItem {
  image: Cms_SingleMedia;
  id: number;
  title: string;
  titleHtmlTag: Cms_TitleTag;
  text?: Cms_EditorData;
  customPrice?: string;
  price: number;
  priceLabel?: string; // default: starting at
  priceCurrency?: string; // default: CHF
  services?: Cms_ContentPricingItemService[];
  link?: Cms_Link;
  links?: Cms_Link[];
  color?: any; // default white
}

export interface Cms_ContentPricing extends Cms_ContentSlice {
  image?: Cms_SingleMedia;
  __component: 'content.pricing';
  title?: string;
  titleHtmlTag?: Cms_TitleTag;
  variant: Cms_GridVariant;
  pricePlans?: Cms_ContentPricingItem[];
}

export const populatePricing = {
  populate: {
    pricePlans: {
      populate: {
        link: populateLink,
        links: populateLink,
        services: true,
        image: true
      }
    },
    contentBlock: true
  }
};
