/**
 * https://www.radix-ui.com/primitives/docs/@/components/switch
 * https://ui.shadcn.com/docs/components/switch
 */

'use client';

import React, { PropsWithChildren, useMemo } from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { cmsLink } from '@pt-group-fe/styled-system/recipes';
import { saveAs } from 'file-saver';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { usePageProps } from '@lib/hooks';
import {
  Cms_Link,
  Cms_SingleMedia,
  Cms_SitemapTreeItem,
  Cms_SocialLink,
  Cms_Target
} from '@lib/services/cms';
import { useTranslation } from '@lib/services/i18n/client';

import type { ButtonProps } from '../button';
import { Button } from '../button';
import { IconType } from '../icon';

import { getNavItemLinkUrl, isLinkScroll } from './cmsLink.utils';

interface CmsLinkProps extends PropsWithChildren {
  link: Cms_Link;
  className?: string;
  button?: ButtonProps;
}

interface CmsNavLinkProps extends PropsWithChildren {
  navItem: Cms_SitemapTreeItem;
  className?: string;
  button?: ButtonProps;
}

interface ContactSocialLinkProps extends PropsWithChildren {
  link: Cms_SocialLink;
  className?: string;
  target?: Cms_Target;
  button?: ButtonProps;
}

const CmsLink = React.forwardRef<HTMLAnchorElement, CmsLinkProps>((props, ref) => {
  const { link, className, children, button } = props;
  const { root } = cmsLink();
  const { t } = useTranslation();
  const path = usePathname();
  const defaultLabel = t('ui.cmsLink.learnMore');

  const handleDonwload = (file?: Cms_SingleMedia) => {
    if (!file?.data) return;

    saveAs(file.data.url, file.data.name);
  };

  if (!link._isValid || !link._url) return null;

  const content = children || (
    <Button
      icon={button?.icon || 'arrow-right'}
      variant={button?.variant || link.buttonType}
      size={button?.size}
      onClick={link._type === 'INTERNAL_DOWNLOAD' ? () => handleDonwload(link.download) : undefined}
    >
      {button?.children || link.title || defaultLabel}
    </Button>
  );

  if (link._type === 'INTERNAL') {
    return (
      <Link
        ref={ref}
        className={cx(root, className)}
        href={link._url}
        scroll={isLinkScroll(link, path)}
      >
        {content}
      </Link>
    );
  }

  if (link._type === 'EXTERNAL') {
    return (
      <a
        ref={ref}
        className={cx(root, className)}
        rel="nofollow"
        href={link._url}
        target={link._target || '_blank'}
      >
        {content}
      </a>
    );
  }

  if (link._type === 'INTERNAL_DOWNLOAD') {
    return content;
  }

  return null;
});
CmsLink.displayName = 'CmsLink';

const CmsNavLink = React.forwardRef<HTMLAnchorElement, CmsNavLinkProps>((props, ref) => {
  const { navItem, className, children, button } = props;
  const {
    params: { lng }
  } = usePageProps();

  const { navLink } = cmsLink();

  const content = useMemo(() => {
    if (children) {
      return children;
    }

    if (button) {
      return (
        <Button icon={button?.icon || 'arrow-right'} variant={button?.variant} size={button?.size}>
          {button?.children || navItem.title}
        </Button>
      );
    }
    if (navItem.title) {
      return navItem.title;
    }

    return null;
  }, [button, children, navItem.title]);

  const url = getNavItemLinkUrl(navItem) || `/${lng}`;

  if (navItem.type === 'internal') {
    return (
      <Link
        ref={ref}
        className={cx(navLink, className)}
        href={url}
        scroll={true}
        target={navItem.target || '_self'}
      >
        {content}
      </Link>
    );
  }

  if (url) {
    return (
      <a
        ref={ref}
        className={cx(navLink, className)}
        rel="nofollow"
        href={url}
        target={navItem.target || '_blank'}
      >
        {content}
      </a>
    );
  }

  return null;
});
CmsNavLink.displayName = 'CmsNavLink';

const CmsSocialLink = React.forwardRef<HTMLAnchorElement, ContactSocialLinkProps>((props, ref) => {
  const { link, className } = props;

  const { socialLink } = cmsLink();

  let icon: IconType | undefined = undefined;
  switch (link.plaform) {
    case 'LINKEDIN':
      icon = 'linkedin';
      break;
    default:
      return undefined;
  }

  let text: string | undefined = undefined;
  switch (link.plaform) {
    case 'LINKEDIN':
      text = 'Linkedin';
      break;
    default:
      return undefined;
  }

  return (
    <a
      ref={ref}
      href={link.link}
      target="_blank"
      rel="nofollow noreferrer"
      className={cx(socialLink, className)}
    >
      <Button
        variant="secondary"
        size="small"
        icon={icon}
        srOnly={`Open ${text || link.plaform} profile`}
      >
        {text}
      </Button>
    </a>
  );
});
CmsSocialLink.displayName = 'CmsSocialLink';

export { CmsLink, CmsNavLink, CmsSocialLink };
