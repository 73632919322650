/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/naming-convention */
/**
 * https://ui.shadcn.com/docs/components/input
 * radix: https://www.radix-ui.com/primitives/docs/components/input
 */
import * as React from 'react';

import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  OutlinedInput
} from '@mui/material';
import { css, cx } from '@pt-group-fe/styled-system/css';
import { input } from '@pt-group-fe/styled-system/recipes';
import { Eye, EyeOff } from 'lucide-react';
import { ControllerRenderProps } from 'react-hook-form';

import {
  FormControl,
  FormFieldResetBtn,
  FormItem,
  FormLabel,
  FormMessage,
  useFormField
} from '../form';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly?: boolean;
  hasPrefix?: boolean;
  error?: boolean;
  increment?: () => void;
  decrement?: () => void;
}

export interface InputPrefixProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly?: boolean;
  icon?: React.ReactNode;
  error?: boolean;
  iconCl?: string;
}

const InputPrefix = React.forwardRef<HTMLInputElement, InputPrefixProps>((props, ref) => {
  const { className, error, icon, iconCl, children, ...rest } = props;
  const cl = input({ error });

  return (
    <div className={cx(cl.prefix, className)} ref={ref} {...rest}>
      {icon && <span className={cx(cl.icon, iconCl)}>{icon}</span>}
      {children}
    </div>
  );
});
InputPrefix.displayName = 'InputPrefix';

export interface InputAffixProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly?: boolean;
  icon?: React.ReactNode;
  error?: boolean;
  iconCl?: string;
}

const InputAffix = React.forwardRef<HTMLInputElement, InputAffixProps>(
  ({ className, icon, error, children, iconCl, ...rest }, ref) => {
    const cl = input({ error });

    return (
      <div className={cx(cl.affix, className)} ref={ref} {...rest}>
        {icon && <span className={cx(cl.icon, iconCl)}>{icon}</span>}
        {children}
      </div>
    );
  }
);
InputAffix.displayName = 'InputAffix';

export interface InputWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly?: boolean;
  disabled?: boolean;
}

const InputWrapper = React.forwardRef<HTMLDivElement, InputWrapperProps>(
  ({ className, disabled, readonly, ...rest }, ref) => {
    const { wrapper } = input({ disabled, readonly });

    return <div className={cx('group', wrapper, className)} ref={ref} {...rest} />;
  }
);
InputWrapper.displayName = 'InputWrapper';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ readonly, disabled, type = 'text', className, error, ...props }, ref) => {
    const { root } = input({ readonly, disabled, error });

    return (
      <input
        type={type}
        disabled={disabled}
        readOnly={readonly}
        className={cx(root, className)}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

const InputPassword = React.forwardRef<HTMLInputElement, InputProps>(
  ({ readonly, disabled, error, ...props }, ref) => {
    const [isVisible, setVisible] = React.useState(false);

    return (
      <InputWrapper>
        <Input
          readonly={readonly}
          disabled={disabled}
          error={error}
          {...props}
          type={isVisible ? 'text' : 'password'}
          ref={ref}
        />
        <InputAffix>
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setVisible(!isVisible);
            }}
            className={css({
              cursor: 'pointer',
              opacity: 0.6,
              transition: 'all',
              _hover: { opacity: 2, color: 'primary' }
            })}
          >
            {!isVisible ? <Eye strokeWidth={1.25} /> : <EyeOff strokeWidth={1.25} />}
          </button>
        </InputAffix>
      </InputWrapper>
    );
  }
);
InputPassword.displayName = 'InputPassword';

export interface FormInputTextProps extends InputProps {
  label: string | React.ReactNode;
  isMui?: boolean;
  required?: boolean;
  field: ControllerRenderProps<any>;
  multiLine?: boolean;
  rows?: number;
  helperText?: string;
}

const InputForm = React.forwardRef<HTMLDivElement, FormInputTextProps>((props, ref) => {
  const { error } = useFormField();
  const {
    color,
    size,
    isMui,
    required,
    helperText,
    label,
    field,
    tabIndex,
    multiLine,
    rows,
    ...rest
  } = props;

  if (isMui) {
    return (
      <FormItem ref={ref}>
        <FormControl>
          <MuiTextField
            //  ref={ref}
            {...rest}
            {...field}
            required={required}
            error={error !== undefined}
            helperText={error?.message || (helperText as any)}
            label={label}
            multiline={multiLine}
            rows={rows}
            // disable native html5 field validation
            inputProps={{ ...rest, required: false, tabIndex }}
          />
        </FormControl>
      </FormItem>
    );
  }

  return (
    <FormItem ref={ref}>
      <FormLabel required>{label}</FormLabel>
      <FormControl>
        <InputWrapper>
          <Input {...rest} {...field} tabIndex={tabIndex} />
          <FormFieldResetBtn {...field} tabIndex={-1} />
        </InputWrapper>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
});
InputForm.displayName = 'InputForm';

export interface InputPasswordProps extends InputProps {
  label?: string | React.ReactNode;
  isMui?: boolean;
  required?: boolean;
  field: ControllerRenderProps;
}

const FormInputPassword = React.forwardRef<HTMLDivElement, InputPasswordProps>((props, ref) => {
  const { error } = useFormField();

  const { color, size, isMui, required, label, field, tabIndex, ...rest } = props;
  const [isVisible, setVisible] = React.useState(false);

  if (isMui) {
    return (
      <FormItem ref={ref}>
        <FormControl>
          <MuiFormControl variant="outlined" error={error !== undefined}>
            <InputLabel required={required} error={error !== undefined} htmlFor={props.id}>
              {label}
            </InputLabel>
            <OutlinedInput
              {...rest}
              {...field}
              required={required}
              error={error !== undefined}
              label={label}
              type={!isVisible ? 'password' : 'text'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setVisible(!isVisible)}
                    edge="end"
                  >
                    {isVisible ? <EyeOff strokeWidth={1.5} /> : <Eye strokeWidth={1.5} />}
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby={`${props.id}-helper-text`}
              inputProps={{ ...rest, required: false, tabIndex }}
            />
            <FormHelperText id={`${props.id}-helper-text`} error={error !== undefined}>
              {error?.message as any}
            </FormHelperText>
          </MuiFormControl>
        </FormControl>
      </FormItem>
    );
  }

  return (
    <FormItem ref={ref}>
      <FormLabel required>{label}</FormLabel>
      <FormControl>
        <InputWrapper>
          <InputPassword {...rest} {...field} tabIndex={tabIndex} />
          <FormFieldResetBtn {...field} tabIndex={-1} />
        </InputWrapper>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
});
FormInputPassword.displayName = 'FormInputPassword';

export { Input, InputAffix, InputForm, InputPassword, InputPrefix, InputWrapper };
