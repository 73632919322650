'use client';

import React from 'react';

import { css } from '@pt-group-fe/styled-system/css';
import { Container, GridItem } from '@pt-group-fe/styled-system/jsx';

import type { Cms_ContentEcosystem } from '@lib/services/cms';

import EcosystemLayerInfo from './EcosystemInfo';
import EcosystemMobileSlider from './EcosystemMobileSlider';
import EcosystemParallaxContainer from './EcosystemParallaxContainer';

type Layers = 1 | 2 | 3;

interface Props {
  data: Cms_ContentEcosystem;
}

const grid = css({
  display: 'grid',
  rowGap: 8,
  columnGap: { md: 8 },
  minH: { md: '75vh', xl: '100vh' },
  gridTemplateColumns: 'repeat(12, minmax(0px, 1fr))',

  '@media (max-width: 1000px)': {
    gridTemplateColumns: 'repeat(1, minmax(0px, 1fr))',
    rowGap: 8
  }
});

const contentBox = css({
  bg: 'white',
  p: { base: 6, xl: 12 },
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: { lg: '110px', xl: '160px' },
  h: 'auto',

  '@media (max-width: 1000px)': {
    mt: 8,
    py: 12
  },

  '@media (max-height: 800px) and (min-width: 1280px)': {
    p: 6,
    gap: 4
  }
});

const infoCol = css({
  gridColumn: 'span 4/span 4',
  h: 'full',

  '@media (max-width: 1000px)': {
    gridColumn: 'span 12/span 12'
  }
});

const mobileContainer = css({
  display: 'none',
  '@media (max-width: 1000px)': {
    display: 'block'
  }
});

const desktopContainer = css({
  display: 'none',
  '@media (min-width: 1001px)': {
    display: 'block'
  }
});

const CmsContentEcosystem: React.FC<Props> = ({ data }) => {
  const [activeLayer, setActiveLayer] = React.useState<Layers>(1);
  //  const windowSize = useWindowSize();

  return (
    <Container>
      <div className={grid}>
        <GridItem colSpan={{ base: 12, md: 8 }} pos="relative">
          {/*  {windowSize.width < 1000 ? (
            <EcosystemMobileSlider setActiveLayer={setActiveLayer} activeLayer={activeLayer} />
          ) : (
            <EcosystemParallaxContainer setActiveLayer={setActiveLayer} activeLayer={activeLayer} />
          )} */}

          <div className={mobileContainer}>
            <EcosystemMobileSlider setActiveLayer={setActiveLayer} activeLayer={activeLayer} />
          </div>
          <div className={desktopContainer}>
            <EcosystemParallaxContainer setActiveLayer={setActiveLayer} activeLayer={activeLayer} />
          </div>
        </GridItem>
        <div className={infoCol}>
          <div className={contentBox}>
            <EcosystemLayerInfo data={data.businessLayer} isVisible={activeLayer === 1} />
            <EcosystemLayerInfo data={data.automationLayer} isVisible={activeLayer === 2} />
            <EcosystemLayerInfo data={data.infrastructureLayer} isVisible={activeLayer === 3} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CmsContentEcosystem;
