'use client';

import React from 'react';

import { Flex } from '@pt-group-fe/styled-system/jsx';

import type {
  Cms_ContentTeam,
  Cms_SingleMedia,
  Cms_TeamMember,
  Cms_TitleTag
} from '@lib/services/cms';
import { LayoutGrid, LayoutGridTile } from '@lib/ui/layout';
import { Text } from '@lib/ui/text';
import { getTitleTag } from '@lib/utils';

import { TeamMemberItem } from './TeamMemberItem';

const bigTitle: Cms_TitleTag[] = ['H1', 'H2'];

interface CmsContentTeamInnerProps {
  teamMembers: Cms_TeamMember[];
  data: Cms_ContentTeam;
  fallbackImg?: Cms_SingleMedia;
  maskingEmail?: string;
}

const CmsContentTeamInner: React.FC<CmsContentTeamInnerProps> = ({
  teamMembers,
  data,
  fallbackImg,
  maskingEmail
}) => {
  return (
    <Flex flexDir="column" gap={12}>
      {data.title && (
        <Text
          leadingNormal
          variant={bigTitle.includes(data.titleHtmlTag) ? 'h2' : 'h4'}
          as={getTitleTag(data.titleHtmlTag, 'h3')}
        >
          {data.title}
        </Text>
      )}
      <LayoutGrid>
        {teamMembers?.map(teamMember => (
          <LayoutGridTile key={teamMember.id}>
            <TeamMemberItem
              teamMember={teamMember}
              data={data}
              fallbackImg={fallbackImg}
              maskingEmail={maskingEmail}
            />
          </LayoutGridTile>
        ))}
      </LayoutGrid>
    </Flex>
  );
};

export { CmsContentTeamInner };
export type { CmsContentTeamInnerProps };
