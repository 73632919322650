import React, { PropsWithChildren } from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { cmsLinkServer } from '@pt-group-fe/styled-system/recipes';
import Link from 'next/link';

import type { Cms_Link, Cms_SitemapTreeItem, Cms_SocialLink, Cms_Target } from '@lib/services/cms';
import { getServerTranslation } from '@lib/services/i18n';
import { PageProps } from '@lib/services/page';
import { routes } from '@lib/services/routes';

import { Button } from '../button';
import type { ButtonProps } from '../button';
import { IconType } from '../icon';

import { getNavItemLinkUrl, isLinkScroll } from './cmsLink.utils';

interface CmsLinkProps extends PropsWithChildren {
  link: Cms_Link;
  className?: string;
  button?: ButtonProps;
  style?: React.CSSProperties;
  pageProps: PageProps;
}

interface CmsNavLinkProps extends PropsWithChildren {
  navItem: Cms_SitemapTreeItem;
  className?: string;
  button?: ButtonProps;
  pageProps: PageProps;
}

interface ContactSocialLinkProps extends PropsWithChildren {
  link: Cms_SocialLink;
  className?: string;
  target?: Cms_Target;
  button?: ButtonProps;
}

const CmsLinkServer = React.forwardRef<HTMLAnchorElement, CmsLinkProps>(async (props, ref) => {
  const { link, className, children, button, pageProps } = props;
  const { t } = await getServerTranslation(pageProps.params.lng);
  const path = routes.getPathFromPageProps(pageProps, true);
  const defaultLabel = t('ui.cmsLink.learnMore');
  const { root } = cmsLinkServer();

  if (!link._isValid || !link._url) return null;

  const content = children || (
    <Button
      icon={button?.icon || 'arrow-right'}
      variant={button?.variant || link.buttonType}
      size={button?.size}
    >
      {button?.children || link.title || defaultLabel}
    </Button>
  );

  if (link._type === 'INTERNAL') {
    return (
      <Link
        ref={ref}
        className={cx(root, className)}
        href={link._url}
        scroll={isLinkScroll(link, path)}
      >
        {content}
      </Link>
    );
  }

  if (link._type === 'EXTERNAL') {
    return (
      <a
        ref={ref}
        className={cx(root, className)}
        rel="nofollow"
        href={link._url}
        target={link._target || '_blank'}
      >
        {content}
      </a>
    );
  }

  return null;
});
CmsLinkServer.displayName = 'CmsLinkServer';

const CmsNavLinkServer = React.forwardRef<HTMLAnchorElement, CmsNavLinkProps>((props, ref) => {
  const { navItem, className, children, button, pageProps } = props;
  const rootPath = routes.getLocalizedRootPath(pageProps);

  const { navLink } = cmsLinkServer();

  const content = navItem.title || children || (
    <Button icon={button?.icon || 'arrow-right'} variant={button?.variant} size={button?.size}>
      {button?.children || navItem.title}
    </Button>
  );
  const url = getNavItemLinkUrl(navItem) || rootPath;

  if (navItem.type === 'internal') {
    /*  if (navItem.target === '_blank') {
      return (
        <a ref={ref} className={clx} href={`${origin}${url}`} target={navItem.target}>
          {content}
        </a>
      );
    } */
    return (
      <Link
        ref={ref}
        className={cx(navLink, className)}
        href={url}
        scroll={true}
        target={navItem.target || '_self'}
      >
        {content}
      </Link>
    );
  }

  <a
    ref={ref}
    className={cx(navLink, className)}
    rel="nofollow"
    href={url}
    target={navItem.target || '_blank'}
  >
    {content}
  </a>;

  return null;
});
CmsNavLinkServer.displayName = 'CmsNavLinkServer';

const CmsSocialLinkServer = React.forwardRef<HTMLAnchorElement, ContactSocialLinkProps>(
  (props, ref) => {
    const { link, className } = props;

    const { socialLink } = cmsLinkServer();

    let icon: IconType | undefined = undefined;
    switch (link.plaform) {
      case 'LINKEDIN':
        icon = 'linkedin';
        break;
      default:
        return undefined;
    }

    let text: string | undefined = undefined;
    switch (link.plaform) {
      case 'LINKEDIN':
        text = 'Linkedin';
        break;
      default:
        return undefined;
    }

    return (
      <a
        ref={ref}
        href={link.link}
        target="_blank"
        rel="nofollow noreferrer"
        className={cx(socialLink, className)}
      >
        <Button
          variant="secondary"
          size="small"
          icon={icon}
          srOnly={`Open ${text || link.plaform} profile`}
        >
          {text}
        </Button>
      </a>
    );
  }
);
CmsSocialLinkServer.displayName = 'CmsSocialLinkServer';

export { CmsLinkServer, CmsNavLinkServer, CmsSocialLinkServer };
