import { isServer, objectToBase64 } from '@lib/utils';

const getImgUrlFromKey = (
  key: string,
  transforms: any = {},
  format: 'jpg' | 'png' | 'webp' = 'webp'
) => {
  const q = objectToBase64(transforms);

  return `/img/${encodeURIComponent(key).replaceAll('.', '%2E')}/${q}.${format}`;
};

const getKeyFormS3Url = (url: string) => {
  const parts = (url.split('//')[1] as string)?.split('/');
  parts.shift();

  // TODO remove hadrcoded bucket fix for client
  const bucket = isServer() ? process.env.CMS_S3_BUCKET : 'cms-phoenix-website';

  return parts.filter(p => p !== bucket).join('/');
};

const getImgUrlFromS3Url = (
  url: string,
  transforms: any = {},
  format: 'png' | 'jpg' | 'webp' = 'webp'
) => {
  const q = objectToBase64(transforms);

  const key = getKeyFormS3Url(url);

  return `/img/${encodeURIComponent(key).replaceAll('.', '%2E')}/${q}.${format}`;
};

const imageService = {
  getImgUrlFromKey,
  getKeyFormS3Url,
  getImgUrlFromS3Url
};

export { imageService as image };
