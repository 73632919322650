'use client';

import React, { useMemo } from 'react';

import { mobileNavigation } from '@pt-group-fe/styled-system/recipes';

import { Cms_SitemapTreeItem } from '@lib/services/cms';
import { CmsNavLink } from '@lib/ui/cmsLink';
import { Icon } from '@lib/ui/icon';

interface NavItemMobileProps {
  data: Cms_SitemapTreeItem;
  handleSubmenuAppereance: (item: Cms_SitemapTreeItem) => void;
  activeItemId: number | undefined;
}

const NavItemMobile: React.FC<NavItemMobileProps> = props => {
  const { navItem, expandIcon, navItemBtn } = mobileNavigation();
  const { data, handleSubmenuAppereance, activeItemId } = props;
  const hasChildren =
    data.children.length > 0 &&
    data.children.filter(item => item.active && item.include.includes('header')).length > 0;

  const iconEl = useMemo(
    () => <Icon className={expandIcon} i="chevron-right" size="small" />,
    [expandIcon]
  );

  const content = useMemo(() => {
    const label = (
      <span className={mobileNavigation({ active: data.id === activeItemId }).navItemLabel}>
        {data.title}
      </span>
    );

    if ((data.type === 'internal' || data.type === 'directory') && hasChildren) {
      return (
        <div className={navItem}>
          <button
            className={navItemBtn}
            type="button"
            onClick={() => handleSubmenuAppereance(data)}
          >
            {label}
            {iconEl}
          </button>
        </div>
      );
    }

    return (
      <div>
        <CmsNavLink navItem={data} className={navItemBtn}>
          {label}
          {iconEl}
        </CmsNavLink>
      </div>
    );
  }, [data, activeItemId, hasChildren, navItemBtn, iconEl, navItem, handleSubmenuAppereance]);

  if (!content) return null;

  return <div>{content}</div>;
};

export { NavItemMobile };
export type { NavItemMobileProps };
