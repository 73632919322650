import {
  Cms_Color,
  Cms_EditorData,
  Cms_Link,
  Cms_ResponsiveVideo,
  Cms_SingleMedia,
  Cms_TitleTag
} from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink, populateResonsiveVideo } from './populateCommon';

export interface Cms_CTATileItem {
  id: number;
  title?: string;
  titleHtmlTag?: Cms_TitleTag;
  teaser?: string;
  tags?: string[];
  text?: Cms_EditorData;
  links?: Cms_Link[];
  image?: Cms_SingleMedia;
  video?: Cms_ResponsiveVideo;
  overalyBgColor?: Cms_Color;
}

export interface Cms_ContentCTATiles extends Cms_ContentSlice {
  __component: 'content.cta-tiles';
  title?: string; // internal
  tiles?: Cms_CTATileItem[];
}

export const populateCTATiles = {
  populate: {
    tiles: {
      populate: {
        image: true,
        video: { ...populateResonsiveVideo },
        contentBlock: true,
        links: populateLink
      }
    }
  }
};
