'use client';
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { menuButton } from '@pt-group-fe/styled-system/recipes';

type MenuButtonProps = {
  className?: string;
  cl?: string;
  open?: boolean;
  onClick?: (open: boolean) => void;
};

const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>((props, ref) => {
  const { className, open, cl, onClick, ...restProps } = props;

  const { root, inner, line } = menuButton({ open: open });

  const handleClick = () => {
    if (!onClick) return;

    onClick(!open);
  };

  return (
    <button
      {...restProps}
      type="button"
      ref={ref}
      onClick={handleClick}
      className={cx(root, className)}
    >
      <div className={inner}>
        <span className={cx(line, 'line-top', cl)} />
        <span className={cx(line, 'line-bottom', cl)} />
      </div>
    </button>
  );
});

MenuButton.displayName = 'MenuButton';

export { MenuButton };
export type { MenuButtonProps };
