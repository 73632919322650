import React, { useMemo } from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { Flex, HStack } from '@pt-group-fe/styled-system/jsx';
import { srOnly } from '@pt-group-fe/styled-system/patterns';
import { cmsContentTeam } from '@pt-group-fe/styled-system/recipes';

import type { Cms_ContentTeam, Cms_SingleMedia, Cms_TeamMember } from '@lib/services/cms';
import { Icon } from '@lib/ui/icon';
import { Img } from '@lib/ui/img';

interface TeamMemberItemProps {
  teamMember: Cms_TeamMember;
  data: Cms_ContentTeam;
  fallbackImg?: Cms_SingleMedia;
  maskingEmail?: string;
}

const TeamMemberItem: React.FC<TeamMemberItemProps> = ({
  teamMember,
  data,
  fallbackImg,
  maskingEmail
}) => {
  const { box, imageBox, image, name, position, link, linkIcon } = cmsContentTeam();

  const email = useMemo(() => {
    if (teamMember.maskEmail) {
      if (maskingEmail) {
        return `${maskingEmail}?subject=ATTN: ${teamMember.firstName} ${teamMember.lastName}`;
      }

      return null;
    }

    return teamMember.email;
  }, [
    maskingEmail,
    teamMember.email,
    teamMember.firstName,
    teamMember.lastName,
    teamMember.maskEmail
  ]);

  const img = useMemo(() => {
    if (teamMember.image?.data) {
      return teamMember.image;
    }
    if (data.fallbackImage?.data) {
      return data.fallbackImage;
    }

    if (fallbackImg?.data) {
      return fallbackImg;
    }

    return null;
  }, [data.fallbackImage, fallbackImg, teamMember.image]);

  return (
    <div className={box}>
      <div className={imageBox}>
        <Img fill data={img} className={image} />
      </div>
      <Flex>
        <div>
          <div className={name}>
            {teamMember.firstName} {teamMember.lastName}
          </div>
          {teamMember.jobTitle && <div className={position}>{teamMember.jobTitle}</div>}
        </div>
        <HStack ml="auto" gap={2} flexShrink={0}>
          {email && (
            <a className={cx('group', link)} href={`mailto:${email}`}>
              <Icon i="envelope" className={linkIcon} />
              <span className={srOnly()}>Send email</span>
            </a>
          )}
          {teamMember.linkedin && (
            <a
              className={cx('group', link)}
              rel="nofollow noreferrer"
              target="_blank"
              href={teamMember.linkedin}
            >
              <Icon i="linkedin" className={linkIcon} />
              <span className={srOnly()}>Open linkedin profile</span>
            </a>
          )}
        </HStack>
      </Flex>
    </div>
  );
};

export { TeamMemberItem };
export type { TeamMemberItemProps };
