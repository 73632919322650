'use client';

import React, { useEffect, useState } from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { video } from '@pt-group-fe/styled-system/recipes';
import { useWindowSize } from 'react-use';

import { Cms_ResponsiveVideo } from '@lib/services/cms';

interface VideoProps {
  data: Cms_ResponsiveVideo;
  className?: string;
  videoCl?: string;
}

const Video = React.forwardRef<HTMLDivElement, VideoProps>(({ className, data, videoCl }, ref) => {
  const [src, setSrc] = useState<string>();
  const { width } = useWindowSize();
  const { wrapper, root } = video();

  useEffect(() => {
    if (!data.videoSm?.data?.url || !data.videoMd?.data?.url || !data.videoLg?.data?.url) return;

    let s = data.videoSm.data.url;

    if (!data.videoSm || data.videoSm.data.url === '') {
      s = data.videoMd.data.url;
    }

    if (!data.videoMd || data.videoMd.data.url === '') {
      s = data.videoLg.data.url;
    }

    if (width >= 720) {
      s = data.videoMd.data.url;
    }

    if (width >= 1080) {
      s = data.videoLg.data.url;
    }

    const parts = s.split('//')[1]?.split('/') || [];
    if (parts[2]) {
      setSrc(`/video/${parts[2]}`);

      return;
    }

    setSrc(s);
  }, [width, setSrc, data]);

  return (
    <div ref={ref} className={cx(wrapper, className)}>
      <video
        className={cx(root, videoCl)}
        data-object-fit="cover"
        data-wf-ignore="true"
        playsInline
        autoPlay
        muted
        loop
        controls={false}
        src={src}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        type="video/mp4"
      />
    </div>
  );
});
Video.displayName = 'Video';

export { Video };
export type { VideoProps };
