import React, { useCallback, useEffect } from 'react';

import { Autocomplete, FormControl, FormHelperText, MenuItem, TextField } from '@mui/material';
import { cva } from '@pt-group-fe/styled-system/css';
import Image from 'next/image';
import { keys } from 'remeda';

import { useIsVisible, useLanguage } from '@lib/hooks';

const opt = cva({
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    py: 1,
    px: 3,
    overflow: 'hidden',
    _hover: {
      bg: 'primary.a10',
      color: 'primary'
    }
  },
  variants: {
    selected: {
      true: {
        bg: 'primary.light',
        color: 'primary.dark'
      }
    }
  }
});

interface SelectOption<T = any> {
  value: T;
  label: string;
}

interface CountrySelectProps {
  value?: string;
  defaultValue?: string;
  onChange?: (value?: string) => void;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode | string;
  disabled?: boolean;
  name?: string;
  id?: string;
  required?: boolean;
  poperContainer?: HTMLElement | (() => HTMLElement) | null;
}

const CountrySelect = React.forwardRef<HTMLDivElement, CountrySelectProps>(
  (
    {
      label,
      value,
      onChange,
      error,
      helperText,
      disabled,
      defaultValue,
      id,
      name,
      required,
      poperContainer
    },
    compRef
  ) => {
    const { isVisible, ref } = useIsVisible();
    const locale = useLanguage();
    const [options, setOptions] = React.useState<SelectOption[]>();

    const handleChange = (e: any, newValue?: SelectOption | null) => {
      if (onChange) onChange(newValue?.value);
    };

    const loadOptions = useCallback(async () => {
      const { getName, registerLocale, getAlpha2Codes } = await import('i18n-iso-countries');

      if (locale === 'en') {
        const countriesEn = await import('i18n-iso-countries/langs/en.json');
        registerLocale(countriesEn);
        const allCountriesAlpha2 = getAlpha2Codes();
        const alpha2 = keys(allCountriesAlpha2);

        const op = alpha2.map(countryCode => ({
          value: countryCode,
          label: getName(countryCode, locale) || countryCode
        }));

        setOptions(op);
      }
      if (locale === 'de') {
        const countriesDe = await import('i18n-iso-countries/langs/de.json');
        registerLocale(countriesDe);
        const allCountriesAlpha2 = getAlpha2Codes();
        const alpha2 = keys(allCountriesAlpha2);

        const op = alpha2.map(countryCode => ({
          value: countryCode,
          label: getName(countryCode, locale) || countryCode
        }));

        setOptions(op);
      }
    }, [locale]);

    useEffect(() => {
      if (options || !isVisible) return;

      loadOptions();
    }, [isVisible, loadOptions, options]);

    return (
      <FormControl ref={ref} required={required}>
        <Autocomplete
          ref={compRef}
          value={{
            value: options?.find(o => o.value === value)?.value || '',
            label: options?.find(o => o.value === value)?.label || ''
          }}
          defaultValue={defaultValue ? options?.find(o => o.value === defaultValue) : null}
          options={options || []}
          isOptionEqualToValue={(option, v) => {
            return option.value === v;
          }}
          slotProps={{ popper: { container: poperContainer } }}
          disabled={disabled}
          ListboxProps={{ style: { maxHeight: 200 } }}
          renderInput={params => (
            <TextField
              {...params}
              id={id}
              name={name}
              disabled={disabled}
              required={required}
              error={error}
              label={label}
              ref={ref}
            />
          )}
          getOptionLabel={option => option.label}
          onChange={handleChange}
          renderOption={(props, option) => (
            <MenuItem component="li" {...props} className={opt()}>
              <Image
                loading="lazy"
                className="flag"
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.value}.svg`}
                alt={option.label}
                width={24}
                height={24}
              />
              <span>{option.label}</span>
            </MenuItem>
          )}
        />
        {helperText && (
          <FormHelperText error={error} disabled={disabled}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
CountrySelect.displayName = 'CountrySelect';

export { CountrySelect };
export type { CountrySelectProps };
