import React from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { CmsEditorTextVariantProps, cmsEditorText } from '@pt-group-fe/styled-system/recipes';

import { Cms_EditorData } from '@lib/services/cms';
import { HtmlTagName } from '@lib/types';

import CmsEditorNode from './EditorNode';

interface CmsEditorTextProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    CmsEditorTextVariantProps {
  data?: Cms_EditorData;
  className?: string;
  isNestedLinks?: boolean;
  as?: HtmlTagName;
  isTable?: boolean;
  forHtml?: boolean;
}

const CmsEditorText = React.forwardRef<HTMLDivElement, CmsEditorTextProps>((props, ref) => {
  const {
    data,
    className,
    variant = 'blockLg',
    isNestedLinks,
    as,
    isTable,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    forHtml,
    style,
    ...rest
  } = props;

  if (!data) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cx(
        cmsEditorText({ variant, table: isTable }),
        `cms-editor-text-${variant}`,
        className
      )}
      {...rest}
    >
      {data.text?.map((node, index) => (
        <CmsEditorNode
          style={style}
          forHtml={forHtml}
          as={as}
          data={node}
          key={index}
          isNestedLinks={isNestedLinks}
        />
      ))}
    </div>
  );
});
CmsEditorText.displayName = 'CmsEditorText';

const EDITOR_TEXT_VARIANTS: CmsEditorTextVariantProps['variant'] = [
  'blockLg',
  'blockSm',
  'blockXs'
];

export { CmsEditorText, EDITOR_TEXT_VARIANTS };
export type { CmsEditorTextProps };
