'use client';

import React, { useEffect } from 'react';

import { css } from '@pt-group-fe/styled-system/css';
import { srOnly } from '@pt-group-fe/styled-system/patterns';
import { useParams, usePathname } from 'next/navigation';

import { Cms_SitemapTree } from '@lib/services/cms';
import { ClientConfig } from '@lib/services/config';
import { useTranslation } from '@lib/services/i18n/client';
import { PageData, PageProps } from '@lib/services/page';
import { Button } from '@lib/ui/button';

const SearchDialog = React.lazy(() => import('./SearchDialog'));

const label = css({
  '@media (max-width: 374px)': {
    display: 'none'
  },
  '@media (min-width: 1280px) and (max-width: 1390px)': {
    display: 'none'
  }
});

interface SearchInnerProps {
  hasHeaderBanner?: boolean;
  sitemap?: Cms_SitemapTree;
  pageProps: PageProps;
  config: ClientConfig;
  pageData: PageData;
}

const SearchInner: React.FC<SearchInnerProps> = ({
  hasHeaderBanner,
  sitemap,
  pageProps,
  config,
  pageData
}) => {
  const { t } = useTranslation();
  const [isSearchOpen, setSearchOpen] = React.useState(false);
  const pathname = usePathname();
  const params = useParams();

  useEffect(() => {
    setSearchOpen(false);
  }, [pathname, params]);

  return (
    <>
      <Button
        size="small"
        variant="tertiary"
        icon="search"
        onClick={() => setSearchOpen(!isSearchOpen)}
        className="ps-btn-search"
        noIconTransform
      >
        <span className={label}>{t('uiWeb.search.searchBtn.label')}</span>
        <span className={srOnly()}>{t('uiWeb.search.searchBtn.srLabel')}</span>
      </Button>

      {isSearchOpen && (
        <SearchDialog
          pageProps={pageProps}
          config={config}
          isOpen={isSearchOpen}
          setOpen={setSearchOpen}
          hasHeaderBanner={hasHeaderBanner}
          sitemap={sitemap}
          pageData={pageData}
        />
      )}
    </>
  );
};

export default SearchInner;
