'use client';

import React from 'react';

import { cmsContentDownloadForm } from '@pt-group-fe/styled-system/recipes';

import { Cms_EditorData } from '@lib/services/cms';
import { Button } from '@lib/ui/button';
import { CmsEditorText } from '@lib/ui/cmsEditorText';
import { Dialog, DialogBody, DialogContent, DialogFooter } from '@lib/ui/dialog';

interface DownloadFormTermsDialogProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  data: Cms_EditorData;
}

const DownloadFormTermsDialog: React.FC<DownloadFormTermsDialogProps> = ({
  isOpen,
  setOpen,
  data
}) => {
  const { termsDialogContent } = cmsContentDownloadForm();

  return (
    <Dialog open={isOpen} onOpenChange={o => setOpen(o)}>
      <DialogContent className={termsDialogContent}>
        <DialogBody>
          <CmsEditorText data={data} variant="blockSm" />
        </DialogBody>
        <DialogFooter>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadFormTermsDialog;
